import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { Row, Col, Button, Descriptions, Collapse } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import VisibleInfo from './VisibleInfo'
import ExportToExcel from './ExportToExcel'
import DeliveryTable from './DeliveryTable'
import { useFormatMessage } from './hooks'
import { useRenderColumn } from '../shared/useColumns'

const mobileBreakPoint = 1025

/**
 * Wrapper for DeliveryTable
 * with responsive version, See all and See less buttons, VisibleInfo config and ExportToExcel
 */
const DeliveryTableWrapper = ({
  dataSource, columns, loading, initialPageSize = 10, showExport = true, showConfig = true
}) => {
  const f = useFormatMessage()
  const navigate = useNavigate()
  const renderColumn = useRenderColumn()

  const [pageSize, setPageSize] = useState(initialPageSize)
  const [orderedColumns, setOrderedColumns] = useState(columns)
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileBreakPoint)

  const updateScreen = () => setIsMobile(window.innerWidth < mobileBreakPoint)

  useEffect(() => {
    window.addEventListener('resize', updateScreen)
    return () => window.removeEventListener('resize', updateScreen)
  }, [])

  const visibleColumns = orderedColumns.filter(col => col.visible)

  const seeLessButton = (
    <Button type="link" onClick={() => setPageSize(initialPageSize)}>
      {f('Table.SeeLess')}
    </Button>
  )

  const seeAllButton = (
    <Button type="link" onClick={() => setPageSize(dataSource.length)}>
      {f('Table.SeeAll')}
    </Button>
  )

  const renderIcon = (item) => (
    <EditOutlined
      onClick={e => {
        e.stopPropagation() // prevent collapse
        navigate(`/about-delivery/${item.businessKey}`)
      }}
    />
  )

  const getLabel = item => [0, 1].map(i => renderColumn(item, visibleColumns[i]?.dataIndex)).join(' ')

  const items = dataSource.map(item => ({
    key: `${item.businessKey}${item.createDate}`,
    label: getLabel(item),
    extra: renderIcon(item),
    children: (
      <Descriptions size="small" column={1} bordered>
        {visibleColumns.map(({ key, title }) =>
          <Descriptions.Item key={key} label={title} span={1}>
            {renderColumn(item, key)}
          </Descriptions.Item>
        )}
      </Descriptions>
    )
  }))

  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        <Col xs={24} xl={8}>
          {showExport &&
            <ExportToExcel dataSource={dataSource} columns={visibleColumns} />
          }
        </Col>

        <Col xs={24} xl={8} style={{ textAlign: 'center' }}>
          {pageSize === dataSource.length && seeLessButton}
        </Col>

        <Col xs={24} xl={8} style={{ display: 'flex', justifyContent: 'end' }}>
          {showConfig &&
            <VisibleInfo
              tableName="NotDelivered"
              columns={orderedColumns}
              setColumns={setOrderedColumns}
              loading={loading}
            />
          }
        </Col>
      </Row>

      {isMobile
        ? <Collapse items={items} />
        :
        <>
          <DeliveryTable
            columns={visibleColumns}
            dataSource={dataSource}
            loading={loading}
            pageSize={pageSize}
          />

          {dataSource.length &&
            <Row className="see-all-row" justify="center">
              {pageSize === dataSource.length ? seeLessButton : seeAllButton}
            </Row>
          }
        </>
      }
    </>
  )
}

export default DeliveryTableWrapper
