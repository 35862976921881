import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ActionList from './Home/ActionList';
import Dashboard from './Home/Dashboard';
import AllShipments from './AllShipments/AllShipments';
import AboutDelivery from './AboutDelivery/AboutDelivery';
import CreateDelivery from './CreateDelivery/CreateDelivery';
import CreateSender from './Admin/CreateSender';
import RegisterReturn from './RegisterReturn/RegisterReturn';
import ErrorNotFound from './ErrorNotFound';
import NotDelivered from './NotDelivered/NotDelivered';
import SearchResults from './SearchResults/SearchResults';
import Profile from './Profile';
import Senders from './Admin/Senders';
import AccessGroups from './Admin/AccessGroups';
import Branches from './Admin/Branches';
import Companies from './Admin/Companies';
import CreateCompany from './Admin/CreateCompany';
import CreateBranch from './Admin/CreateBranch';
import CreateUser from './Admin/CreateUser';
import TemplateEditor from './Templates/TemplateEditor';
import Login from './Login';
import { PERMISSION } from '../Constants'
import { useHavePermission } from './shared/hooks'

const AppRoutes = () => {
  const havePermission = useHavePermission()

  const home = havePermission(PERMISSION.readDashboard)
    ? <Dashboard />
    : <ActionList />

  return (
    <Routes>
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/"
        element={home}
      />
      <Route
        path="/dashboard"
        element={<Dashboard />}
      />
      <Route
        path="/action-list"
        element={<ActionList />}
      />
      <Route
        path="/delivery"
        element={<AllShipments />}
      />
      <Route
        path="/search-shipment"
        element={<SearchResults />}
      />
      <Route
        path="/admin/create-branch"
        element={<CreateBranch />}
      />
      <Route
        path="/admin/create-company"
        element={<CreateCompany />}
      />
      <Route
        path="/admin/create-user"
        element={<CreateUser />}
      />
      <Route
        path="/template-editor"
        element={<TemplateEditor />}
      />
      <Route
        path="/create-delivery"
        element={<CreateDelivery />}
      />
      <Route
        path="/collection"
        element={<RegisterReturn />}
      />
      <Route
        path="/admin/create-sender"
        element={<CreateSender />}
      />
      <Route
        path="/about-delivery/:businessKey"
        element={<AboutDelivery />}
      />
      <Route
        path="/not-delivered"
        element={<NotDelivered />}
      />
      <Route
        path="/settings/profile"
        element={<Profile />}
      />
      <Route
        path="/admin/access-groups"
        element={<AccessGroups />}
      />
      <Route
        path="/admin/senders"
        element={<Senders />}
      />
      <Route
        path="/admin/branches"
        element={<Branches />}
      />
      <Route
        path="/admin/companies"
        element={<Companies />}
      />
      <Route
        path="*"
        status={404}
        element={<ErrorNotFound />}
      >
      </Route>
    </Routes>
  )
}

export default AppRoutes