import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, message } from 'antd';
import DOMPurify from 'dompurify';

import { notificationAPI } from '../../AxiosWrapper';

const Preview = ({ form, getOwnerJson, missingFields, isType, locale }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const previewEmail = () => {
    const templateType = 'email'
    const ownerJson = getOwnerJson(templateType)
    const templateName = form.getFieldValue('templateName')
    const url = `Notification/${ownerJson}/${templateType}/${templateName}/${locale}/renderTemplate?templateParameters={}`

    setLoading(true)

    notificationAPI.GET(url)
      .then(({ data }) => setData(data))
      .catch(() => message.error('Could not load preview.'))
      .finally(() => setLoading(false))
  }

  const openPreview = () => {
    if (isType('email')) previewEmail()
  }

  const buttonText = isType('email') ? 'PreviewEmail' : 'PreviewSMS'

  return (
    <>
      <Modal
        centered
        open={Boolean(data)}
        onCancel={() => setData(null)}
        width={800}
        styles={{ body: { padding: 0 } }}
        footer={null}
      >
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
      </Modal>

      <Button
        type="primary"
        style={{ marginRight: 16 }}
        loading={loading}
        onClick={openPreview}
        disabled={missingFields() || isType('sms')}
      >
        <FormattedMessage id={`TemplateEditor.${buttonText}`} />
      </Button>
    </>
  )
}

export default Preview
