import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { Form, Row, Layout, Col, Checkbox, Input, Typography } from 'antd';

import { filterData, objectIncludes } from '../shared/utils';
import { API } from '../../AxiosWrapper';
import Sender from '../shared/Sender';
import { useColumns, searchableProps } from '../shared/useColumns';
import DateRange from '../shared/DateRange';
import DeliveryTableWrapper from '../shared/DeliveryTableWrapper'
import { SearchIcon } from '../shared/icons'
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import { UserContext } from '../shared/context'

const { Item } = Form;
const { Title } = Typography

const AllShipments = () => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()
  const columns = useColumns()
  const { defaultSenderId, defaultBranchId: branchId } = useContext(UserContext)

  const [deliveries, setDeliveries] = useState([]);
  const [senderId, setSenderId] = useState(defaultSenderId);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [showOnlyDelivered, setShowOnlyDelivered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');

  const getDeliveryData = () => {
    if (!startDate || !endDate) return;

    let url = `Delivery?DateFrom=${startDate.toISOString()}&DateTo=${endDate.toISOString()}`
    if (branchId) url += `&branchId=${branchId}`;
    if (senderId) url += `&senderId=${senderId}`;

    setLoading(true);
    API.GET(url)
      .then(response => setDeliveries(response.data.reverse()))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getDeliveryData, [branchId, endDate, senderId, startDate])

  const onDateChange = (values) => {
    if (!values) return;
    const { startValue, endValue } = values;
    setStartDate(startValue);
    setEndDate(endValue);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value)
  }

  const filteredDeliveries = filterData(({ senderId, showOnlyDelivered, startDate, endDate }), deliveries)
    .filter(obj => objectIncludes(obj, searchableProps, searchString));

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.AllShipments" /></Title>

      <Form layout="vertical">
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
              <Input
                className="standard-input"
                suffix={<SearchIcon />}
                onChange={handleSearch}
                placeholder={f('Input.SearchList')}
              />
            </Item>
          </Col>
          <Col xs={24} lg={8}>
            <DateRange
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          <Col xs={24} lg={8} className="justify-end-responsive">
            <Sender senderId={senderId} setSenderId={setSenderId} />
          </Col>
        </Row>
        <Row>
          <Col xs={24} style={{ marginBottom: 8 }}>
            <Checkbox defaultChecked={showOnlyDelivered} onChange={e => setShowOnlyDelivered(e.target.checked)}>
              <FormattedMessage id="AllShipments.ShowOnlyDelivered" />
            </Checkbox>
          </Col>
        </Row>
      </Form>

      <DeliveryTableWrapper
        columns={columns}
        dataSource={filteredDeliveries}
        loading={loading}
      />

    </Layout.Content>
  );
}

export default AllShipments;
