import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Form, Layout, Row, Col, Button, Radio, Checkbox, Typography } from 'antd';

import { SHOW_SHELF_BUTTON, PERMISSION } from '../../Constants';
import { API } from '../../AxiosWrapper';
import BoxInfo from './BoxInfo';
import { checkPermission, getSanitizedPhone } from '../shared/utils';
import { useFormatMessage, useHandleApiError } from '../shared/hooks'
import Email from '../shared/delivery/Email'
import Phone from '../shared/delivery/Phone'
import Sender from '../shared/delivery/Sender'
import RentalCar from '../shared/delivery/RentalCar'
import InputField from '../shared/delivery/InputField'
import Shelves from './Shelves'
import Boxes from './Boxes'
import { UserContext, DataContext } from "../shared/context"

const { Item } = Form
const { Title } = Typography

const CreateDelivery = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const handleApiError = useHandleApiError();
  const f = useFormatMessage();
  const { permissions, defaultSenderId, defaultBranchId: branchId } = useContext(UserContext)
  const { companyId, branches } = useContext(DataContext)

  const [posting, setPosting] = useState(false);
  const [lockers, setLockers] = useState(null);
  const [radioValue, setRadioValue] = useState(1)
  const [returningOld, setReturningOld] = useState(false)

  const isBox = radioValue === 1

  const getBoxSizes = (branchId) => {
    API.GET(`Site/${branchId}/lockerTotals`)
      .then(response => setLockers(response.data))
      .catch(handleApiError)
  };

  useEffect(() => {
    form.setFieldValue("senderId", defaultSenderId)
  }, [form, defaultSenderId])

  useEffect(() => {
    if (branchId === 0) return;
    getBoxSizes(branchId);
  }, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  const getBoxSize = (boxSize) => boxSize === 'none' ? null : boxSize;

  const getRelatedInfo = (values) => {
    if (!returningOld) return null;
    return isBox
      ? {
        boxSize: values.oldBoxSize,
        description: values.oldItemDescription
      }
      : {
        location: values.oldLocation,
        description: values.oldItemDescription
      }
  }

  const handleSubmit = (values) => {
    setPosting(true);
    const newData = {
      deliveryBranchId: branchId,
      senderOrderId: values.senderOrderId,
      companyId: companyId,
      description: values.description || f("CreateDelivery.NoDescription"),
      senderId: values.senderId,
      recipient: {
        identificationNumber: values.kennitala?.replace(/\D/g, ''),
        name: values.recipientName,
        email: values.email ? values.email : null,
        phone: getSanitizedPhone(values.phone),
        street: values.street,
      },
      numberOfPackages: 1,
      createDate: values.createDate,
      location: !isBox ? values.location : null, // only for shelf
      box: isBox,
      boxSize: isBox ? getBoxSize(values.boxSize) : null, // only for box
      relatedDeliveryInfo: getRelatedInfo(values),
      rentalCar: values.rentalCar,
    };

    API.POST('Delivery', newData)
      .then(res => navigate(`/about-delivery/${res.data}`))
      .catch(handleApiError)
      .finally(() => setPosting(false));
  }

  if (branchId === 0) return "Branch is not selected."
  if (branches.length === 0) return "No branches"
  const siteName = branches.find(b => b.id === branchId)?.name

  return (
    <Layout.Content>
      <Title level={2} style={{ marginBottom: 16 }}><FormattedMessage id="CreateDelivery.DeliveryTitle" /></Title>

      <Row>
        <Col xs={24} lg={12}>
          <Form
            layout="vertical"
            form={form}
            validateMessages={{
              required: f("Validation.Default.Required"),
              whitespace: f("CreateDelivery.FieldWhitespaceError"),
            }}
            className="form-style"
            preserve={true}
            onFinish={handleSubmit}
          >
            <Sender orderType="delivery" editing={true} />
            <InputField name="kennitala" />
            <InputField name="recipientName" />
            <InputField name="street" />
            <Email />
            <Phone />
            <hr />
            <InputField name="senderOrderId" />
            <InputField name="description" />
            <RentalCar />

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
              <Radio.Group
                className="register-tabs-radio"
                onChange={e => setRadioValue(e.target.value)}
                value={radioValue}
              >
                <Radio value={1}>{f('CreateDelivery.Box')}</Radio>
                {SHOW_SHELF_BUTTON &&
                  <Radio value={2}>{f('CreateDelivery.Shelf')}</Radio>
                }
              </Radio.Group>

              {checkPermission(permissions, PERMISSION.createRelatedShipmentDeliveryForSender)
                && (
                  <Checkbox checked={returningOld} onChange={e => setReturningOld(e.target.checked)}>
                    {f('CreateDelivery.Return')}
                  </Checkbox>
                )}
            </div>

            {isBox
              ? <Boxes lockers={lockers} returningOld={returningOld} />
              : <Shelves returningOld={returningOld} />
            }

            <Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                loading={posting}
              >
                {f(isBox ? 'CreateDelivery.Confirm' : 'CreateDelivery.AssignShelf')}
              </Button>
            </Item>
          </Form>
        </Col>

        <Col xs={24} lg={12}>
          <BoxInfo lockers={lockers} siteName={siteName} />
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default CreateDelivery;
