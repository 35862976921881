import React, { useContext } from 'react';
import { Layout, Select, Row, Col, theme } from 'antd';
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import PoweroffOutlined from '@ant-design/icons/PoweroffOutlined';
import { isEmpty } from 'lodash';

import HeaderSearch from './HeaderSearch';
import { useTheme, useFormatMessage } from '../shared/hooks'
import { API } from '../../AxiosWrapper';
import { UserContext, DataContext } from "../shared/context"

const { Option } = Select;
const { Header } = Layout;

const SiteHeader = ({
  setLocale, setUserTheme
}) => {
  const f = useFormatMessage()
  const { locale } = useIntl()
  const navigate = useNavigate()
  const antTheme = useTheme()
  const {
    token: { colorBgContainer }
  } = theme.useToken()
  const user = useContext(UserContext)
  const { branches, handleBranchChange } = useContext(DataContext)

  const handleLocaleChange = locale => {
    setLocale(locale)
    localStorage.setItem('locale', locale)
  }

  const handleThemeChange = theme => {
    setUserTheme(theme)
    localStorage.setItem('userTheme', theme)
  }

  const handleUserMenu = key => {
    if (key === 'logout')
      API.logout();
    if (key === 'profile')
      navigate('/settings/profile')
    if (key === 'lang')
      handleLocaleChange(locale === 'en' ? 'is' : 'en')
    if (key === 'theme')
      handleThemeChange(antTheme === 'dark' ? 'light' : 'dark')
  }

  const menuItems = [
    {
      key: 'profile',
      label: <FormattedMessage id="Header.Profile" />,
    },
    {
      key: 'lang',
      label: locale === 'en' ? 'Íslenska' : 'English'
    },
    {
      key: 'theme',
      label: <FormattedMessage id={antTheme === 'dark' ? 'Theme.LightMode' : 'Theme.DarkMode'} />
    },
    {
      key: 'logout',
      label: <><PoweroffOutlined />{' '}<FormattedMessage id="Header.Logout" /></>,
    }
  ];

  return (
    <Header style={{ background: colorBgContainer }}>
      <Row>
        <Col xs={24} xl={8}>
          {user && !isEmpty(branches)
            ? (
              <Select
                value={user.defaultBranchId}
                onSelect={handleBranchChange}
                className="header-select"
                style={{ border: 'none' }}
              >
                {branches.length > 1 &&
                  <Option key={0} value={0}>
                    <FormattedMessage id="Header.AllBranches" />
                  </Option>
                }
                {branches.map((branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <Select
                disabled
                placeholder={f('Branch.Unavailable')}
                className="header-select"
              />
            )}
        </Col>

        <Col xs={24} xl={16} style={{ textAlign: 'right' }}>
          <HeaderSearch />

          {user && (
            <Select
              value={user.firstName}
              onSelect={handleUserMenu}
              className="header-select"
              style={{ textAlign: 'left' }}
            >
              {menuItems.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
    </Header>
  )
}

export default SiteHeader
