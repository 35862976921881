import React from 'react';
import { Button, Col, Form, Input, Row, Space, message } from 'antd';
import { FormattedMessage } from 'react-intl';

import { API } from '../../AxiosWrapper';
import { SHOW_SHELF_BUTTON } from '../../Constants';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form

const isInBox = stateId =>
  stateId === 'customer-picks-up-from-box' ||
  stateId === 'customer-identification' ||
  stateId === 'send-pickup-code' ||
  stateId === 'customer-pickup' ||
  stateId === 'send-staff-reminder' ||
  stateId === 'send-box-reminder'

const canMoveToShelf = stateId => SHOW_SHELF_BUTTON && (
  stateId === 'move-package-from-box' ||
  stateId === 'pick-shelf-or-box' ||
  stateId === 'package-moved-from-box'
)

const canCancelCollectionAfterSendingToCustomer = stateId =>
  stateId === 'collection-placed-in-box' ||
  stateId === 'collection-reminder-notification' ||
  stateId === 'collection-in-bad-locker-notification' ||
  stateId === 'related-shipment-delivered' ||
  stateId === 'related-delivery-check-timer' ||
  stateId === 'customer-check-delivery-notification' ||
  stateId === 'verify-locker' ||
  stateId === 'not-in-locker'

const BottomButtons = ({
  form, editing, stateId, setEditing, businessKey, handleSubmit, setLocation, setDelivered, posting, setPosting
}) => {
  const f = useFormatMessage();
  const handleApiError = useHandleApiError();

  const resendNotification = () => {
    setPosting(true)
    API.POST('Delivery/' + businessKey + '/resend-last-notification')
      .then(() => message.success(f('Generic.Success')))
      .catch(handleApiError)
      .finally(() => setPosting(false))
  };

  const requestLockdown = () => {
    setPosting(true)
    API.POST('Delivery/' + businessKey + '/lockdown')
      .then(() => message.success(f('Generic.Success')))
      .catch(handleApiError)
      .finally(() => setPosting(false))
  };

  const moveToShelfOld = () => {
    form.validateFields(['shelfLocation'])
      .then(({ shelfLocation }) => {
        const data = {
          businessKey: businessKey,
          location: shelfLocation,
        };
        const url = 'Delivery/' + businessKey + '/move-to-shelf?location=' + shelfLocation;
        setPosting(true)
        API.POST(url, data)
          .then(() => setLocation(shelfLocation))
          .catch(handleApiError)
          .finally(() => setPosting(false))
      })
  };

  const handleDecide = (businessKey, method, isDelivered, shelfLocation) => {
    const path = (stateId === 'decide-delivery-method-pre-lockdown')
      ? 'decide-delivery-method-pre-lockdown'
      : 'decide-delivery-method'

    let fullPath = path + '?method=' + method;
    if (method === 'SHELF') {
      fullPath += '&location=' + shelfLocation;
    } else {
      form.setFieldValue('shelfLocation', '')
    }
    if (isDelivered) {
      setDelivered(true);
    }
    handleSubmit(businessKey, fullPath);
  };

  const handleDecideDeliverShelf = () => {
    form.validateFields(['shelfLocation'])
      .then(({ shelfLocation }) => {
        setLocation(shelfLocation)
        handleDecide(businessKey, 'SHELF', false, shelfLocation);
      })
  };

  const handleBtn = (command) => {
    form.setFieldValue('shelfLocation', '')
    handleSubmit(businessKey, command);
  };

  const handleDeliver = (command) => {
    setDelivered(true);
    handleSubmit(businessKey, command);
  };

  if (editing) return (
    <Button shape="round" type="primary" htmlType="submit" loading={posting}>
      <FormattedMessage id="AboutDelivery.Save" />
    </Button>
  )

  return (
    <>
      <Item>
        <Button shape="round" type="primary" onClick={() => setEditing(true)}>
          <FormattedMessage id="AboutDelivery.ChangeInformation" />
        </Button>
      </Item>

      {(stateId === 'customer-picks-up-from-reception' || stateId === 'send-shelf-reminder') && (
        <>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleDeliver('deliver-to-customer')}
            >
              <FormattedMessage id="AboutDelivery.DeliverPackage" />
            </Button>
          </Item>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleBtn('cancel-shelf')}
            >
              <FormattedMessage id="AboutDelivery.MoveFromShelf" />
            </Button>
          </Item>

          {isInBox(stateId) && (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={resendNotification}
                loading={posting}
              >
                <FormattedMessage id="CreateDelivery.ResendNotification" />
              </Button>
            </Item>
          )}
        </>
      )}

      {stateId === 'decide-delivery-method-pre-lockdown' && (
        <Item>
          <Button
            shape="round"
            type="primary"
            onClick={() => handleDecide(businessKey, 'UNDECIDED', false)}
          >
            <FormattedMessage id="CreateDelivery.DecideLater" />
          </Button>
        </Item>
      )}

      {(stateId === 'decide-delivery-method' || stateId === 'decide-delivery-method-pre-lockdown') && (
        <>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleDecide(businessKey, 'COUNTER', true)}
            >
              <FormattedMessage id="CreateDelivery.DeliverPackage" />
            </Button>
          </Item>
          {SHOW_SHELF_BUTTON && (
            <Space.Compact>
              <Item
                name="shelfLocation"
                rules={[{
                  required: true,
                  message: f('CreateDelivery.ShelfRequired'),
                }]}
              >
                <Input
                  placeholder={f("CreateDelivery.ShelfPlaceholder")}
                  style={{ width: '180px' }}
                />
              </Item>
              <Item>
                <Button
                  shape="round"
                  type="primary"
                  onClick={handleDecideDeliverShelf}
                  loading={posting}
                >
                  <FormattedMessage id="CreateDelivery.MoveToShelf" />
                </Button>
              </Item>
            </Space.Compact>
          )}
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleDecide(businessKey, 'BOX', false)}
            >
              <FormattedMessage id="CreateDelivery.SendToBox" />
            </Button>
          </Item>
        </>
      )}

      {stateId === 'return-to-sender' && (
        <>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleDeliver('deliver-to-customer-late')}
            >
              <FormattedMessage id="CreateDelivery.DeliverPackage" />
            </Button>
          </Item>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleDeliver('lockdown-for-return')}
            >
              <FormattedMessage id="CreateDelivery.Resend" />
            </Button>
          </Item>
        </>
      )}

      {(stateId === 'sender-picks-up-from-reception') && (
        <Item>
          <Button
            shape="round"
            type="primary"
            onClick={() => handleDeliver('deliver-to-sender')}
          >
            <FormattedMessage id="CreateDelivery.DeliverToSender" />
          </Button>
        </Item>
      )}

      {stateId === 'order-placed-in-box' && (
        <Item>
          <Button
            shape="round"
            type="alert"
            onClick={() => handleBtn('cancel-box-before')}
          >
            <FormattedMessage id="CreateDelivery.CancelBox" />
          </Button>
        </Item>
      )}

      {stateId === 'decide-delivery' && (
        <>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleBtn('cancel-box')}
            >
              <FormattedMessage id="CreateDelivery.CancelBox" />
            </Button>
          </Item>
          <Item>
            <Button
              shape="round"
              type="secondary"
              onClick={() => handleBtn('verify-item-in-box')}
            >
              <FormattedMessage id="CreateDelivery.VerifyItemInBox" />
            </Button>
          </Item>
        </>
      )}

      {canMoveToShelf(stateId)
        ? (
          <Row gutter={24} style={{ width: '100%' }}>
            <Col span={{ xs: 24, sm: 24, md: 24, lg: 10 }} >
              <Item style={{ float: 'left' }}>
                <Button
                  shape="round"
                  type="primary"
                  onClick={moveToShelfOld}
                  loading={posting}
                >
                  <FormattedMessage id="CreateDelivery.MoveToShelf" />
                </Button>
              </Item>
            </Col>
            <Col span={{ xs: 24, sm: 24, md: 24, lg: 10 }}>
              <Item
                style={{ float: 'left' }}
                name="shelfLocation"
                rules={[{
                  required: true,
                  message: f('CreateDelivery.ShelfRequired')
                }]}
              >
                <Input />
              </Item>
            </Col>
          </Row>)
        : isInBox(stateId)
          ? (
            <Item>
              <Button
                shape="round"
                type="primary"
                onClick={requestLockdown}
                loading={posting}
              >
                <FormattedMessage id="CreateDelivery.RemoveFromBox" />
              </Button>
            </Item>
          ) : null
      }

      {stateId === 'verify-reserve' && (
        <>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleBtn('verify-collection-before')}
            >
              <FormattedMessage id="CreateCollection.VerifyReservation" />
            </Button>
          </Item>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleBtn('cancel-collection-before')}
            >
              <FormattedMessage id="CreateCollection.CancelReservation" />
            </Button>
          </Item>
        </>
      )}

      {canCancelCollectionAfterSendingToCustomer(stateId) && (
        <Item>
          <Button
            shape="round"
            type="primary"
            onClick={() => handleBtn('cancel-collection-waiting')}
          >
            <FormattedMessage id="CreateCollection.CancelBox" />
          </Button>
        </Item>
      )}

      {stateId === 'verify-collection' && (
        <>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleBtn('verify-collection')}
            >
              <FormattedMessage id="CreateCollection.VerifyCollectionReceived" />
            </Button>
          </Item>
          <Item>
            <Button
              shape="round"
              type="primary"
              onClick={() => handleBtn('reject-collection')}
            >
              <FormattedMessage id="CreateCollection.RejectCollection" />
            </Button>
          </Item>
        </>
      )}
    </ >
  )
}

export default BottomButtons;
