import React from 'react';
import { Row, Col, Typography, Table, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { labelStyle } from './style';
import { groupBy } from '../shared/utils.js'
import { sizeTranslations } from '../shared/delivery/BoxSize'
import { useFormatMessage } from '../shared/hooks.js'

const { Text } = Typography;

const FreeCompartments = ({ data, loading, branchName }) => {
  const f = useFormatMessage()

  const lockerTotals = data?.map(lt => {
    const sizeName = sizeTranslations[lt.size]
    return {
      ...lt,
      sizeName,
    }
  })

  const groupedBySite = Array.from(groupBy(lockerTotals, l => l.siteName));

  const dataSource = groupedBySite.map(([siteName, lockerTotals]) => {
    const ret = { siteName, key: siteName }
    // create the data values that will be looked up by columns
    lockerTotals.forEach(lockerTotal => {
      ret[lockerTotal.sizeName] = { available: lockerTotal.numAvailable, total: lockerTotal.numTotal }
    });
    return ret
  })

  const renderFreeLockers = value => value ? `${value.available} / ${value.total}` : '-'

  const columns = [
    {
      title: f('Home.Location'),
      dataIndex: 'siteName',
    },
    {
      title: f('Box.Size.Keys'),
      dataIndex: 'Box.Size.Keys',
      render: renderFreeLockers,
    },
    {
      title: f('Box.Size.Small'),
      dataIndex: 'Box.Size.Small',
      render: renderFreeLockers,
    },
    {
      title: f('Box.Size.Mid'),
      dataIndex: 'Box.Size.Mid',
      render: renderFreeLockers,
    },
    {
      title: f('Box.Size.Big'),
      dataIndex: 'Box.Size.Big',
      render: renderFreeLockers,
    },
  ];

  if (loading) return <Spin />;

  return (
    <Row gutter={24} className="free-compartments">
      <Col xs={24}>
        <Text strong style={labelStyle}>
          <FormattedMessage id="Home.FreeCompartments" />
        </Text>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className="small-table"
          rowClassName={rec => rec.siteAddress1 === branchName ? 'highlight-row' : ''}
        />
      </Col>
    </Row>
  )
}

export default FreeCompartments;
