import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import { Layout, Col, Form, Row, Typography, Spin, message } from 'antd';
import { get, isEqual } from 'lodash';
import dayjs from 'dayjs';

import { API } from '../../AxiosWrapper';
import ShippingHistory from './ShippingHistory';
import BottomButtons from './BottomButtons';
import PrintButton from './PrintButton';
import Barcode from 'react-barcode';
import { useHandleApiError, useFormatMessage, useInterval } from '../shared/hooks'
import ErrorNotFound from '../ErrorNotFound';
import Email from '../shared/delivery/Email'
import Phone from '../shared/delivery/Phone'
import RentalCar from '../shared/delivery/RentalCar'
import InputField from '../shared/delivery/InputField'
import Sender from '../shared/delivery/Sender';
import { DataContext } from "../shared/context"
import { SHOW_PRINT_BUTTON } from '../../Constants';

const { Title } = Typography

const barcodeLabelOptions = {
  height: 55,
  width: 2,
  marginLeft: 8,
  marginRight: 8,
  marginTop: 0,
  marginBottom: 1,
  displayValue: false,
  format: 'CODE128',
};

const AboutDelivery = () => {
  const { senders, branches } = useContext(DataContext)
  const [form] = Form.useForm();
  const handleApiError = useHandleApiError()
  const f = useFormatMessage()
  const { businessKey } = useParams()

  const [delivered, setDelivered] = useState(false);
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false)
  const [posting, setPosting] = useState(false)

  const getBranchName = (branches, branchId) => {
    const branch = branches.find(b => b.id === branchId)
    return branch?.name || f('Branch.NotDefined')
  }

  const valuesFromData = (data, branches) => ({
    senderId: get(data, 'sender.id'),
    email: get(data, 'recipient.email'),
    phone: get(data, 'recipient.phone'),
    kennitala: get(data, 'recipient.identificationNumber'),
    rentalCar: data.rentalCar,
    createDate: data.createDate ? dayjs(data.createDate).format('YYYY-MM-DD HH:mm') : null,
    createdByUserName: data.createdByUserName,
    recipientName: get(data, 'recipient.name'),
    businessKey: data.businessKey,
    street: get(data, 'recipient.street'),
    senderOrderId: data.senderOrderId,
    description: data.description,
    location: data.location,
    currentState: get(data, 'currentState.name'),
    apexStatus: data.apexStatus,
    branchName: getBranchName(branches, data.deliveryBranchId),
  })

  // NOTE: useCallback creates bug (not updating data and creates endless loop)
  const getFormData = (shipNum, branches, oldData) => {
    if (!shipNum || !branches || editing) return
    if (!oldData) setLoading(true) // show loader first time only

    API.GET(`Delivery/${shipNum}`)
      .then(res => {
        if (isEqual(oldData, res.data)) return

        form.setFieldsValue(valuesFromData(res.data, branches))
        setData(res.data)
      })
      .catch(error => {
        handleApiError(error)
        if (error.response?.status === 404)
          setNotFound(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getFormData(businessKey, branches)
  }, [businessKey, branches]) // eslint-disable-line react-hooks/exhaustive-deps

  // NOTE: setInterval creates closure bug, use this hook instead
  useInterval(() => getFormData(businessKey, branches, data), 2000)

  useEffect(() => {
    if (senders.length === 1)
      form.setFieldValue("senderId", senders[0].id);
  }, [senders, form])

  const handlePatch = () => {
    form.validateFields(['phone', 'email', 'senderId'])
      .then(({ senderId, phone, email }) => {

        const senderData = [
          {
            path: '/sender/id',
            op: 'replace',
            value: senderId,
          }
        ]
        const recipientData = [
          {
            path: '/phone',
            op: 'replace',
            value: phone,
          },
          {
            path: '/email',
            op: 'replace',
            value: email,
          },
        ]

        const promises = []

        if (senderId !== get(data, 'sender.id'))
          promises.push(API.PATCH('Delivery/' + data.businessKey, senderData))

        if (email !== get(data, 'recipient.email') || phone !== get(data, 'recipient.phone'))
          promises.push(API.PATCH('Delivery/' + data.businessKey + '/recipient', recipientData))

        if (!promises.length) return

        setPosting(true)
        Promise.all(promises)
          .then(() => {
            setEditing(false)
            message.success(f('Generic.Success'))
          })
          .catch(handleApiError)
          .finally(() => setPosting(false))
      })
  }

  const handleSubmit = (businessKey, command) => {
    const url = 'Delivery/' + (businessKey ? businessKey : '') + '/' + command;

    form.validateFields(
      [
        'createDate', 'businessKey', 'senderId', 'recipientName', 'email', 'phone', 'street', 'senderOrderId', 'description', 'numberOfPackages', 'delivered',
      ])
      .then(values => {
        const postData = {
          createDate: values.createDate,
          businessKey: values.businessKey,
          senderId: values.senderId,
          recipient: {
            name: values.recipientName,
            email: values.email,
            phone: values.phone,
            street: values.street,
          },
          senderOrderId: values.senderOrderId,
          description: values.description,
          numberOfPackages: values.numberOfPackages,
          location: values.location,
          delivered: delivered,
        };
        API.POST(url, postData)
          .catch(handleApiError);

        getFormData(businessKey, branches, data);
      })
  }

  const stateId = get(data, 'currentState.stateId');

  const title = businessKey
    ? 'AboutDelivery.Title'
    : data?.orderType === 'collection'
      ? 'CreateDelivery.CollectionTitle'
      : 'CreateDelivery.DeliveryTitle'

  const validateMessages = {
    required: f("Validation.Default.Required"),
    whitespace: f("CreateDelivery.FieldWhitespaceError"),
  }

  if (loading) return <Spin size="large" />

  if (notFound) return (<ErrorNotFound />)

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id={title} /></Title>
      <Row>
        <Col xs={24} xl={12}>
          <Form
            form={form}
            layout="vertical"
            className="form-style"
            onFinish={handlePatch}
            validateMessages={validateMessages}
          >
            <InputField name="createDate" disabled={editing} readOnly={true} />
            <InputField name="createdByUserName" disabled={editing} readOnly={true} />
            <InputField name="businessKey" disabled={editing} readOnly={true} />
            <Sender orderType={data.orderType} editing={editing} />
            <InputField name="recipientName" disabled={editing} readOnly={true} />
            <InputField name="kennitala" disabled={editing} readOnly={true} />
            <InputField name="street" disabled={editing} readOnly={true} />
            <Email readOnly={!editing} />
            <Phone readOnly={!editing} />
            <InputField name="senderOrderId" disabled={editing} readOnly={true} />
            <InputField name="description" disabled={editing} readOnly={true} />
            <RentalCar disabled={true} />
            <InputField name="currentState" disabled={editing} readOnly={true} />
            <InputField name="location" disabled={editing} readOnly={true} />
            <InputField name="apexStatus" disabled={editing} readOnly={true} />
            {/* only show branch when user has access to multiple branches */}
            {branches.length > 1 &&
              <InputField name="branchName" disabled={editing} readOnly={true} />
            }

            <BottomButtons
              form={form}
              stateId={stateId}
              editing={editing}
              setEditing={setEditing}
              posting={posting}
              setPosting={setPosting}
              businessKey={data.businessKey}
              handleSubmit={handleSubmit}
              setLocation={(val) => form.setFieldValue('location', val)}
              setDelivered={setDelivered}
            />
          </Form>
        </Col>
        <Col xs={24} xl={10}>
          <ShippingHistory
            data={data}
            delivered={delivered}
          />
          {
            // show print button if it is allowed and if it makes sense
            SHOW_PRINT_BUTTON && stateId === 'order-placed-in-box' && data.deliveryCode && !delivered
            &&
            <PrintButton>
              {data.recipient?.identificationNumber}
              <div>
                <Barcode
                  className="BarcodeOutBox"
                  {...barcodeLabelOptions}
                  value={data.deliveryCode}
                />
              </div>
            </PrintButton>
          }
        </Col>
      </Row>
    </Layout.Content >
  );
}

export default AboutDelivery;
