import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Modal, Button, Input, Select, Typography } from 'antd'
import 'react-quill/dist/quill.snow.css'

import { notificationAPI } from '../../AxiosWrapper'
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form
const { Option } = Select
const { Title } = Typography

const Parameters = ({ getOwnerJson, missingFields, locale }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()

  const [modalOpen, setModalOpen] = useState(false)
  const [parameter, setParameter] = useState(null)

  const loadParameters = () => {
    if (missingFields()) return
    const templateType = 'email-parameter'

    const ownerJson = getOwnerJson(templateType)
    const templateName = 'location_attribute' // TODO: get existing values from API
    const url = `Notification/${ownerJson}/${templateType}/${templateName}/${locale}`

    notificationAPI.GET(url)
      .then(({ data }) => setParameter(data))
      .catch(handleApiError)
  };

  useEffect(loadParameters, [getOwnerJson, missingFields, locale]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Item name="parameterValue" label={f('Input.Value')}>
          <Select placeholder="...">
            <Option key={0} value={0}>...</Option>
            {parameter?.templateId &&
              <Option key={parameter.id} value={parameter.templateId}>{parameter.text}</Option>
            }
          </Select>
        </Item>
      </Col>

      <Col span={24} align="right">
        <Button onClick={() => setModalOpen(true)}>
          + {f('TemplateEditor.NewValue')}
        </Button>
      </Col>

      <Modal
        className="parameter-modal"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button
            key="cancel"
            danger
            onClick={() => setModalOpen(false)}
          >
            {f('CreateSender.Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => setModalOpen(false)}
          >
            {f('CreateSender.Save')}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Title level={3} style={{ textAlign: 'center' }}>{f('TemplateEditor.NewValue')}</Title>
          <Item name="valueName" label={f('TemplateEditor.ValueName')}>
            <Input />
          </Item>
          <Item name="variableName" label={f('TemplateEditor.VariableName')}>
            <Input />
          </Item>
          <Item name="variableConnection" label={f('TemplateEditor.VariableConnection')}>
            <Select placeholder={f('TemplateEditor.ChooseConnection')} />
          </Item>
        </Form>
      </Modal>
    </Row>
  )
}

export default Parameters
