import React, { useState, useCallback } from 'react';
import { Input, AutoComplete } from 'antd';
import { useNavigate } from "react-router-dom";
import { debounce, isEmpty } from 'lodash';

import { API } from '../../AxiosWrapper';
import { SearchIcon } from '../shared/icons'
import { useFormatMessage } from '../shared/hooks'

const HeaderSearch = () => {
  const navigate = useNavigate();
  const f = useFormatMessage()

  const [searchString, setSearchString] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const search = (searchString) => {
    API.GET('Delivery?limit=8&searchString=' + searchString)
      .then(response => setSuggestions(response.data))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(searchString => search(searchString), 300),
    [], // will be created only once initially
  );

  const handleSearch = (searchString) => {
    setSearchString(searchString);
    if (searchString.length < 2) {
      setSuggestions([]);
      return;
    }
    debouncedSearch(searchString);
  }

  let match = null

  function traverseForMatch(obj) {
    for (const key in obj) {
      const value = obj[key];
      if (typeof value === 'string' && value.toLowerCase().includes(searchString.toLowerCase())) {
        match = { key, value };
        return;
      }
      if (value && typeof value === 'object') {
        traverseForMatch(value);
      }
    }
  }

  const mapSuggestion = (delivery) => {
    traverseForMatch(delivery);
    if (isEmpty(match)) return delivery.recipient.name;

    const re = new RegExp(searchString, "gi");
    const replaced = match.value.replace(re, (match) => "<b>" + match + "</b>");
    return (
      <>
        <i>{match.key}</i> : <span dangerouslySetInnerHTML={{ __html: replaced }} /> | {delivery.recipient.name} | {delivery.description}
      </>
    )
  }

  const onPressEnter = () => {
    if (searchString?.length < 2) return

    setSearchString("")
    navigate(`/search-shipment?searchString=${searchString}`)
  }

  const options = suggestions.map(delivery => ({
    label: mapSuggestion(delivery),
    value: delivery.businessKey,
  }))

  return (
    <AutoComplete
      options={options}
      onSearch={handleSearch}
      onSelect={businessKey => navigate(`/about-delivery/${businessKey}`)}
      defaultActiveFirstOption={false}
      popupMatchSelectWidth={false}
      value={searchString}
      className="header-search"
    >
      <Input
        placeholder={f('Header.Search.Placeholder')}
        suffix={<SearchIcon onClick={onPressEnter} />}
        onPressEnter={onPressEnter}
      />
    </AutoComplete>
  );
}

export default HeaderSearch;
