export const blacklistedStatuses = [
  'decide-pickup',
  'transform-config',
  'delivery-order-created',
  'decide-pickup-location',
  'box-timer-reminder',
  'request-lockdown',
  'delete-apex-order',
  'reception-send-reminder-timer',
  'lockdown-notification-timeout',
  'BoundaryEvent_0h6szie',
  'send-lockdown-notification',
  'cancel-lockdown-notification',
  'box-collect-timer',
  'EndEvent_038psog',
  'EndEvent_1qksubt',
  'StartEvent_1ameh8c',
  'fake',
  'customer-check-delivery-notification',
  'related-delivery-check-timer',
  'verify-locker'
];

export const PERMISSION = {
  createRelatedShipmentDeliveryForSender: 'CreateRelatedShipmentDeliveryForSender',
  createCollection: 'CreateCollectionForBranch',
  readCollection: 'ReadCollectionForBranch',

  readDashboard: 'ReadDashboard',

  createSender: 'CreateSender',
  readSender: 'ReadSender',
  editSender: 'EditSender',

  createCompany: 'CreateCompany',
  readCompany: 'ReadCompany',
  editCompany: 'EditCompany',

  createBranch: 'CreateBranch',
  readBranch: 'ReadBranch',
  editBranch: 'EditBranch',

  readTemplate: 'ReadTemplate',
  editTemplate: 'EditTemplate',

  createUser: 'CreateUser',
  adminGroup: 'AdminGroup',
  accessAll: 'AccessAll'
};

export const SHOW_SHELF_BUTTON = process.env.REACT_APP_SHOW_SHELF_BUTTON === "true"
export const SHOW_PRINT_BUTTON = process.env.REACT_APP_SHOW_PRINT_BUTTON === "true"
export const AUTH_SERVER_LINK = process.env.REACT_APP_AUTH_SERVER_LINK
